import React from 'react';
import './skills.css';
import { BsPatchCheckFill } from 'react-icons/bs';

const SKILL_NAME = {
  // Frontend
  HTML: 'HTML',
  CSS: 'CSS',
  JAVASCRIPT: 'Javascript',
  BOOTSTRAP: 'Bootstrap',
  REACT: 'React',

  // Backend
  NODE_JS: 'Node JS',
  PYTHON: 'Python',
  'C++': 'C++',
  MONGODB: 'MongoDB',
  MYSQL: 'MySQL'
};

const SKILL_EXPERTISE = {
  BASIC: 'Basic',
  INTERMEDIATE: 'Intermediate',
  EXPERIENCED: 'Advanced'
};

const PROFESSIONAL_COMPETENT_SKILLS = [
  {
    skillDomain: 'Frontend Development',
    skillDomainId: 'FE',
    skillSet: [
      {
        skillName: SKILL_NAME.HTML,
        skillExpertise: SKILL_EXPERTISE.INTERMEDIATE,
        skillId: SKILL_NAME.HTML
      },
      {
        skillName: SKILL_NAME.CSS,
        skillExpertise: SKILL_EXPERTISE.INTERMEDIATE,
        skillId: SKILL_NAME.CSS
      },
      {
        skillName: SKILL_NAME.JAVASCRIPT,
        skillExpertise: SKILL_EXPERTISE.INTERMEDIATE,
        skillId: SKILL_NAME.JAVASCRIPT
      },
      {
        skillName: SKILL_NAME.BOOTSTRAP,
        skillExpertise: SKILL_EXPERTISE.INTERMEDIATE,
        skillId: SKILL_NAME.BOOTSTRAP
      },
      {
        skillName: SKILL_NAME.REACT,
        skillExpertise: SKILL_EXPERTISE.INTERMEDIATE,
        skillId: SKILL_NAME.REACT
      }
    ]
  },
  {
    skillDomain: 'Backend Development',
    skillDomainId: 'BE',
    skillSet: [
      {
        skillName: SKILL_NAME.NODE_JS,
        skillExpertise: SKILL_EXPERTISE.EXPERIENCED,
        skillId: SKILL_NAME.NODE_JS
      },
      {
        skillName: SKILL_NAME.MONGODB,
        skillExpertise: SKILL_EXPERTISE.EXPERIENCED,
        skillId: SKILL_NAME.MONGODB
      },
      {
        skillName: SKILL_NAME.MYSQL,
        skillExpertise: SKILL_EXPERTISE.INTERMEDIATE,
        skillId: SKILL_NAME.MYSQL
      },
      {
        skillName: SKILL_NAME.PYTHON,
        skillExpertise: SKILL_EXPERTISE.INTERMEDIATE,
        skillId: SKILL_NAME.PYTHON
      },
      {
        skillName: SKILL_NAME['C++'],
        skillExpertise: SKILL_EXPERTISE.INTERMEDIATE,
        skillId: SKILL_NAME['C++']
      }
    ]
  }
];

const Skills = () => {
  return (
    <section id="skills">
      <h5>Professional Competent</h5>
      <h2>Skills I Have</h2>
      <div className="container skills__container">
        {PROFESSIONAL_COMPETENT_SKILLS.map(({ skillDomain, skillSet, skillDomainId }) => {
          return [
            <div key={skillDomainId} className="skills__each">
              <h3 key={skillDomainId}>{skillDomain}</h3>
              <div key={skillDomainId} className="skill__content">
                {skillSet.map(({ skillId, skillExpertise, skillName }) => {
                  return [
                    <article key={skillId} className="skill__details">
                      <BsPatchCheckFill key={skillId} className="skill__detail-icon" />
                      <div>
                        <h4 key={skillId}>{skillName}</h4>
                        <small key={skillId} className="text-light">
                          {skillExpertise}
                        </small>
                      </div>
                    </article>
                  ];
                })}
              </div>
            </div>
          ];
        })}
      </div>
    </section>
  );
};

export default Skills;
