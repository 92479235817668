import React from 'react';
import './about.css';
import ABOUT_ME from '../../assets/me-dheeraj-2.png';
import { FaAward } from 'react-icons/fa';
import { VscFolderLibrary } from 'react-icons/vsc';
import { GiImperialCrown } from 'react-icons/gi';

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ABOUT_ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>3+ Years</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>20+ Completed</small>
            </article>

            <article className="about__card">
              <GiImperialCrown className="about__icon" />
              <h5>Achievements</h5>
              <small>5+ Awards</small>
            </article>
          </div>
          <p>
            I am language agnostic software engineer by profession and a keen learner by passion. I
            have 3+ years of experience as a Fullstack Developer, and in this journey i worked
            across multiple needle moving projects.
          </p>
          <p>
            Currenlty i am working on building simple techincal solutions to complex problems at
            Urban Company.
          </p>
          <a href="#contact" className="btn btn-primary">
            {"Let's Talk"}
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
