import React from 'react';
import './testimonials.css';
import AVTR1 from '../../assets/urban_company.jpeg';
// import AVTR2 from '../../assets/synopsys.jpeg';
import AVTR3 from '../../assets/crio.do.jpeg';
import AVTR4 from '../../assets/coding_ninjas.jpeg';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const TESTIMONIALS = [
  {
    id: 1,
    avatar: AVTR1,
    name: 'Urban Company',
    review: 'Got awarded with great start award in 2021 within Urban Company Engineering Applause'
  },
  {
    id: 4,
    avatar: AVTR4,
    name: 'Coding Ninjas',
    review: 'Resolved more than 150 doubts on the platform with an average rating of 4.5/5.'
  },
  {
    id: 3,
    avatar: AVTR3,
    name: 'Crio.do',
    review:
      'Received amazing feedback from the Crio.Do students for the interviews conducted throughout the tenure.'
  }
];

const Testimonial = () => {
  return (
    <section id="testimonial">
      {/* <h5>Review from clients</h5> */}
      <h2>Testimonials</h2>
      <Swiper
        className="container testimonials__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}>
        {TESTIMONIALS.map(({ id, avatar, name, review }) => {
          return (
            <SwiperSlide key={id} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} alt="" />
              </div>
              <h5 className="client__name">{name}</h5>
              <small className="client__review">{review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonial;
