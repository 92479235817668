import React from 'react';
import './experiences.css';
import { CgProfile } from 'react-icons/cg';
import { BsCalendar4Week } from 'react-icons/bs';
import { CiLocationOn } from 'react-icons/ci';
import { FcTodoList } from 'react-icons/fc';
import { BiCheck } from 'react-icons/bi';
/**
 *   {
    COMPANY_NAME: 'Urban Company',
    TENEURE: 'Jan 2022 - Present',
    DESIGNATION: 'Software Developer - 2',
    LOCATION: 'Gurugram',
    EXPERIENCE_KEY: 1,
    KEY_RESPONSIBILITES: [
      {
        RESPONSIBILITY:
          'Designed and developed the marketplace order and order orchestration flow which enabled company to serve user for longer periods without bothering user for repeated information. E.g: Customer do not have to book every time for a daily cleaner from UC.',
        RESPONSIBILITY_KEY: 1
      },
      {
        RESPONSIBILITY:
          'Worked on the central penalty system which evaluates the applicable penalty incase things go wrong in different scenarios like order cancellation, reschedule etc. System is also responsbile for refunding the penalty based on re-evaluation on users request.',
        RESPONSIBILITY_KEY: 2
      },
      {
        RESPONSIBILITY:
          'Involved in hiring activites for Engineeging team. Interviewed multiple folks across different engineering campuses.',
        RESPONSIBILITY_KEY: 3
      }
    ]
  },
  {
    COMPANY_NAME: 'Urban Company',
    TENEURE: 'Nov 2020 - Dec 2021',
    DESIGNATION: 'Software Developer - 1',
    LOCATION: 'Gurugram',
    EXPERIENCE_KEY: 2,
    KEY_RESPONSIBILITES: [
      {
        RESPONSIBILITY:
          'Worked on the platformizing request lifecylce involving request creation, cancellation, reschedule and revisit.',
        RESPONSIBILITY_KEY: 1
      },
      {
        RESPONSIBILITY:
          'Built the MVP for placing and tracking the painting material order. Which saved the daily manual effort of OPS team.',
        RESPONSIBILITY_KEY: 1
      },
      {
        RESPONSIBILITY:
          'Designed and developed the handshake flow for painting category which enabled the painter to audit the scope of work and material received before starting the work.',
        RESPONSIBILITY_KEY: 1
      }
    ]
  },
 */
const EXPERIENCE = [
  {
    COMPANY_NAME: 'Urban Company',
    TENEURE: 'Nov 2020 - Present',
    DESIGNATION: 'Software Developer - 2',
    LOCATION: 'Gurugram',
    EXPERIENCE_KEY: 1,
    KEY_RESPONSIBILITES: [
      {
        RESPONSIBILITY:
          'Designed and developed the marketplace order and order orchestration flow which enabled company to serve user for longer periods without bothering user for repeated information. E.g: Customer do not have to book every time for a daily cleaner from UC.',
        RESPONSIBILITY_KEY: 1
      },
      {
        RESPONSIBILITY:
          'Worked on the platformizing request lifecylce involving request creation, cancellation, reschedule and revisit.',
        RESPONSIBILITY_KEY: 2
      },
      {
        RESPONSIBILITY:
          'Involved in hiring activites for Engineeging team. Interviewed multiple folks across different engineering campuses.',
        RESPONSIBILITY_KEY: 3
      }
    ]
  },
  // {
  //   COMPANY_NAME: 'Urban Company',
  //   TENEURE: 'Nov 2020 - Dec 2021',
  //   DESIGNATION: 'Software Developer - 1',
  //   LOCATION: 'Gurugram',
  //   EXPERIENCE_KEY: 2,
  //   KEY_RESPONSIBILITES: [
  //     {
  //       RESPONSIBILITY:
  //         'Worked on the platformizing request lifecylce involving request creation, cancellation, reschedule and revisit.',
  //       RESPONSIBILITY_KEY: 1
  //     },
  //     {
  //       RESPONSIBILITY:
  //         'Built the MVP for placing and tracking the painting material order. Which saved the daily manual effort of OPS team.',
  //       RESPONSIBILITY_KEY: 1
  //     },
  //     {
  //       RESPONSIBILITY:
  //         'Designed and developed the handshake flow for painting category which enabled the painter to audit the scope of work and material received before starting the work.',
  //       RESPONSIBILITY_KEY: 1
  //     }
  //   ]
  // },
  {
    COMPANY_NAME: 'Synopsys',
    TENEURE: 'Jan 2020 - Nov 2020',
    DESIGNATION: 'Research and Development Engineer',
    LOCATION: 'Noida',
    EXPERIENCE_KEY: 3,
    KEY_RESPONSIBILITES: [
      {
        RESPONSIBILITY: 'Built the Reset controller, Clock controller for virtual MCUs.',
        RESPONSIBILITY_KEY: 1
      },
      {
        RESPONSIBILITY:
          'Developed the integration and unit tests for different components of MCUs.',
        RESPONSIBILITY_KEY: 2
      }
    ]
  },
  {
    COMPANY_NAME: 'Crio.Do',
    TENEURE: 'Jun 2020 - Sep 2020',
    DESIGNATION: 'Technical Interviewer',
    LOCATION: 'Remote',
    EXPERIENCE_KEY: 4,
    KEY_RESPONSIBILITES: [
      {
        RESPONSIBILITY:
          'Took mock techincal interviews of the young engineers to help them succeed in the real interview setting',
        RESPONSIBILITY_KEY: 1
      }
    ]
  },
  {
    COMPANY_NAME: 'Coding Ninjas',
    TENEURE: 'Jul 2019 - Nov 2019',
    DESIGNATION: 'Teaching Assistant',
    LOCATION: 'Remote',
    EXPERIENCE_KEY: 6,
    KEY_RESPONSIBILITES: [
      {
        RESPONSIBILITY:
          'Helped the students at coding ninjas to master problem solving and data structure skills',
        RESPONSIBILITY_KEY: 1
      }
    ]
  }
];

const Experience = () => {
  return (
    <section id="experience">
      <h5>Industry</h5>
      <h2>Work Experience</h2>

      <div className="container experiences_container">
        {EXPERIENCE.map(
          ({
            COMPANY_NAME,
            TENEURE,
            LOCATION,
            KEY_RESPONSIBILITES,
            DESIGNATION,
            EXPERIENCE_KEY
          }) => {
            return (
              <article key={EXPERIENCE_KEY} className="experience">
                <div className="experience__head">
                  <h3>{COMPANY_NAME}</h3>
                </div>
                <ul className="experience__list">
                  <li>
                    <CgProfile className="service__list-icon" />
                    <p>{DESIGNATION}</p>
                  </li>
                  <li>
                    <BsCalendar4Week className="service__list-icon" />
                    <p>{TENEURE}</p>
                  </li>
                  <li>
                    <CiLocationOn className="service__list-icon" />
                    <p>{LOCATION}</p>
                  </li>
                  <div className="reponsibilities__list">
                    <li>
                      <div className="responsibility__header">
                        <FcTodoList className="service__list-icon" />
                        <p>Key Responsibilites</p>
                      </div>
                      <div className="reponsibilities__list-section">
                        {KEY_RESPONSIBILITES.map(({ RESPONSIBILITY, RESPONSIBILITY_KEY }) => {
                          return (
                            <article key={RESPONSIBILITY_KEY} className="reponsibility">
                              <li>
                                <BiCheck className="service__list-icon-responsibility" />
                                <p>{RESPONSIBILITY}</p>
                              </li>
                            </article>
                          );
                        })}
                      </div>
                    </li>
                  </div>
                </ul>
              </article>
            );
          }
        )}
      </div>
    </section>
  );
};

export default Experience;
