import React from 'react';
import { BsLinkedin, BsInstagram } from 'react-icons/bs';

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/dheerajsingla/" target="_blank" rel="noreferrer">
        <BsLinkedin />
      </a>
      <a href="https://www.instagram.com/dheerajkumarsingla/" target="_blank" rel="noreferrer">
        <BsInstagram />
      </a>
    </div>
  );
};

export default HeaderSocials;
