import React from 'react';
import './footer.css';
import { BsLinkedin, BsInstagram } from 'react-icons/bs';

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo">
        DKS
      </a>
      <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#skills">Skills</a>
        </li>
        {/* <li>
          <a href="#portfolio">Portfolio</a>
        </li> */}
        <li>
          <a href="#testimonial">Testimonial</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
      <div className="footer__socials">
        <a href="https://www.instagram.com/dheerajkumarsingla/" target="_blank" rel="noreferrer">
          <BsInstagram />
        </a>
        <a href="https://www.linkedin.com/in/dheerajsingla/" target="_blank" rel="noreferrer">
          <BsLinkedin />
        </a>
      </div>
      <div className="footer__copyright">
        <small>&copy; DKS. All rights reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
